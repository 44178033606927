import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { graphql } from "gatsby";
import WhitepaperLayout from "../../../components/layouts/whitepaper_layout";
export const pageQuery = graphql`
    query {
        thumbnail: file(
            relativePath: {
                eq: "images/resources/whitepapers/why-waiting-for-sales-to-qualify-is-killing-conversion-rates.jpg"
            }
        ) {
            childImageSharp {
                fixed(height: 221) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
    }
`;
export const _frontmatter = {
  "name": "Why waiting for sales to qualify is killing conversion rates",
  "title": "Improve your return on marketing investment and enjoy better sales engagement - White Paper",
  "description": "A white paper by DemandByte that explores how engaging with prospects during their moment of interest drives higher conversion rates."
};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = WhitepaperLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Learn how to capture your prospects in their moment of interest, improve the quality of your leads and gain credibility with you sales team in this short paper. Our white paper `}<u>{`"Why waiting for sales to qualify your marketing inquiries is `}<i>{`killing`}</i>{` your conversion rates"`}</u>{` walks you through several common problems marketers face in engaging both their prospects as well as their sales teams. We provide a number of suggestions on how to get your prospects in their moment of interest, capture the qualification information needed by your sales team, and improve sales engagement with your leads.`}</p>
    <p>{`Ready to start reaching your prospects in their moment of interest? Call us at +1 (646) 979-0013 to discuss a free trial of TeleQueue!`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      